<template>
  <div class="container">
    <header-back name='积分兑换'></header-back>
    <section class="top44">
      <div class="main">
        <div class="pointsImag">
          <van-image
            width="100%"
            height="100%"
            :src="require('../../assets/image/home/prion-banner2.png')"
          />
        </div>

        <div class="pointsClassly">

          <div
            class="category-list"
            v-for="(item,index) in pointsList"
            :key='index'
          >
            <div
              class="category-column"
              @click="tabClick(item)"
            >
              <van-image
                :src="item.icon"
                width="28"
                height="25"
              />

              <div class="category-title">{{item.name}}</div>
            </div>
          </div>

        </div>

      </div>

    </section>

    <overlay-view
      :overlayShow.sync='overlayShow'
      @login='login'
    ></overlay-view>
  </div>
</template>
<script>
import OverlayView from "../../components/overlay-view";
import HeaderBack from "../../components/header-back";
import data from "../../utils/data";
import { Toast } from "vant";
export default {
  components: { HeaderBack, OverlayView },
  data() {
    return {
      overlayShow: false,
      pointsList: data.pointsList,
    };
  },
  computed: {
    isLogin() {
      return this.$store.getters["user/isLogin"];
    },
    userType() {
      return this.$store.getters["user/userType"];
    },
  },
  created() {},

  methods: {
    tabClick(item) {
      if (item.type === "1") {
        if (this.userType > 0) {
          this.$router.push({
            name: "mobile",
            params: {
              typeDialag: 1,
            },
          });
        } else {
          window.location.href = item.path;
          // this.$router.push("/mall");
          // this.$store.commit("menu/bottomId", 1);
        }
      } else if (item.type === "8") {
        if (this.userType > 0) {
          this.$router.push({
            name: "cardDetail",
          });
        } else {
          window.location.href = item.path;
        }
      } else if (item.type === "2") {
        window.location.href = item.path;
      } else if (item.type === "3") {
        window.location.href = item.path;
      } else if (item.type === "9") {
        if (this.userType > 0) {
          this.$router.push({
            name: "Ecard",
            params: {
              typeDialag: 1,
            },
          });
        } else {
          window.location.href = item.path;
        }
      } else if (item.type === "10") {
        if (this.userType > 0) {
          this.$router.push({
            name: "MGselected",
            params: {
              typeDialag: 1,
            },
          });
        } else {
          window.location.href = item.path;
        }
      } else {
        Toast("努力开发中....");
      }

      // if (!this.phone) {
      //   this.overlayShow = true;
      //   return;
      // }
      // this.$router.push("/mall");
    },
    login() {
      //登录
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../styles/variables.scss";
.container {
  padding: 16px 15px;
  box-sizing: border-box;
  height: 100%;
  background: #fff;
}
.main {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
.pointsImag {
  width: 100%;
  // height: 120px;
  background-color: #fff;
}
.pointsClassly {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 16px 0;
  position: inherit;
}

.category-list {
  width: 25%;
  text-align: center;
  display: inline-block;
  overflow: hidden;
}

.category-column {
  width: 100%;
  margin-top: 14px;
  overflow: hidden;
}

.category-title {
  font-size: 12px;
  color: #777273;
  text-align: center;
  padding-top: 4px;
}
</style>
