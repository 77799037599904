<template>
  <van-overlay
    :show="overlayShow"
    @click="overlayClick"
    class-name='myOverlay'
  >
    <div class="wrapper">
      <div
        class="block"
        @click.stop
      >
        <div class="box">

          <div>
            <van-field
              v-model="loginForm.mobile"
              type="digit"
              label=""
              maxlength='11'
              clearable
              placeholder="请输入手机号码"
            />
            <van-field
              v-model="loginForm.captcha"
              center
              clearable
              type="digit"
              maxlength='6'
              placeholder="请输入短信验证码"
            >
              <template #button>
                <span
                  style="cursor: pointer;"
                  :style="{color:variables.textColor}"
                  @click="getVerify()"
                  v-html="htmlText"
                >发送验证码</span>
              </template>
            </van-field>
            <van-field name="checkbox">
              <template #input>
                <van-checkbox
                  v-model="checked"
                  :checked-color="variables.textColor"
                  icon-size="0.5rem"
                  style="color: #8c8c8c"
                >同意</van-checkbox>
                <span
                  :style="{color:variables.textColor}"
                  @click="protocol"
                >《积分兑换协议》</span>
              </template>
            </van-field>
            <div style="margin: 16px">
              <van-button
                round
                block
                type="info"
                native-type="submit"
                :color="variables.textColor"
                @click="login()"
              >登录</van-button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </van-overlay>
</template>
<script>
// 登录弹框组件
import { Toast, Dialog } from "vant";
import API from "../../api/http.js";
import validateRule from "@/utils/validateRule";
import variables from "@/styles/variables.scss";
import utils from "../../utils/index";
export default {
  props: {
    overlayShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loginForm: {
        mobile: "",
        captcha: "",
      },
      timer: null,
      count: 0,
      htmlText: "发送验证码",
      checked: true,
      address: "",
    };
  },
  computed: {
    variables() {
      return variables;
    },
  },
  watch: {
    overlayShow(val) {
      if (val) {
        this.setFrom();
      }
    },
  },
  created() {},
  mounted() {
    if (utils.getStore("address")) {
      this.address = utils.getStore("address");
    }
  },
  methods: {
    getAddress() {
      let that = this;
      var geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(function (r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          that.getCity(r.point);
        }
      });
      return new Promise((resolve) => {
        setTimeout(() => resolve(that.address), 1000);
      });
    },
    getCity(point) {
      let that = this;
      var point = new BMap.Point(point.lng, point.lat); //用所定位的经纬度查找所在地省市街道等信息
      var gc = new BMap.Geocoder();
      gc.getLocation(point, function (rs) {
        // var addComp = rs.addressComponents;

        that.address = rs.address;
      });
    },
    setFrom() {
      this.loginForm.captcha = "";
    },
    overlayClick() {
      this.$emit("update:overlayShow", false);
    },
    getVerify() {
      // 验证手机号
      if (!this.loginForm.mobile) {
        Toast("请输入手机号码");
        return;
      }
      if (!validateRule.checkPhoneAndTel(this.loginForm.mobile)) {
        //手机号格式不正确
        Toast("手机号码输入错误");
        return;
      }
      const TIME_COUNT = 60; // 更改倒计时时间
      if (!this.timer) {
        // 发送验证码
        Toast.loading({
          message: "加载中...",
          forbidClick: true,
        });
        this.count = TIME_COUNT;
        API.postSendSms({ mobile: this.loginForm.mobile }).then((response) => {
          Toast.clear();
          Toast("发送成功");
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
              this.htmlText = `${this.count}s`;
            } else {
              clearInterval(this.timer); // 清除定时器
              this.htmlText = "重新获取";
              this.count = 60;
              this.timer = null;
            }
          }, 1000);
        });
      }
    },
    async login() {
      if (!this.loginForm.mobile) {
        Toast("请输入手机号码");
        return;
      }
      if (!this.loginForm.captcha) {
        Toast("请输入验证码");
        return;
      }
      if (!this.checked) {
        Toast("请勾选同意");
        return;
      }
      if (this.loginForm.captcha.length < 6) {
        Toast("验证码输入错误");
        return;
      }
      if (!this.address) {
        this.address = await this.getAddress();
      }
      this.loginForm.address = this.address;
      this.$emit("login", this.loginForm);
    },
    protocol() {
      Dialog.alert({
        title: "移动积分商城商品兑换服务协议",
        messageAlign: "left",
        message: `
<p style="margin:0">在使用移动积分商城商品代兑换服务之前，请您(即用户)仔细阅读下述协议条款。一旦您点击确认本协议，即表示您已接受了以下所述的条款和条件，同意受本协议约束。如果您不同意接受全部的条款和条件，那么您将无法使用本协议约定的技术服务。</p>
<p style="margin:0">第一条服务说明</p>
<p style="margin:0">1.1通过桔子网络进行积分商品兑换时，可自由选择自行通过您自有的移动积分商城账户和密码登陆进行兑换，也可选择将您的商城账户和密码授权给桔子网络进行代兑换。</p>
<p style="margin:0">1.2为了更好地给您提供商品代兑换服务，一旦您选择桔子网络代兑换服务，您同意将您的移动积分商城账户和密码授权给桔子网络，允许桔子网络使用您的移动积分商城账户和密码登陆，并完成商品兑换及其其他相关操作。</p>
<p style="margin:0">1.3接受此协议，代表自愿委托使用移动积分商城进行商品兑换。</p>
<p style="margin:0">第二条各方权利和义务</p>
<p style="margin:0">2.1桔子网络提供的是移动积分商城商品代兑换服务，您接受本协议，意味着您同意我们使用您填写的收货地址进行代兑换，同时您必须遵守移动积分商城相关规定(https://jf.10086.cn)。</p>
<p style="margin:0">2.2用户了解并同意，桔子网络会根据您的授权使用您的移动积分商城账户及密码操作代兑换事宜，为了后续进一步向您提供服务，也会在系统中记录您的前述相关信息。但是对于您的隐私信息及相关交易信息，桔子网络会严格保护。</p>
<p style="margin:0">2.3您同意在每次使用本服务并登陆您的移动积分商城账户后将账户中的历史订单同步到桔子网络，用于您进一步的查询和操作</p>
<p style="margin:0">2.4桔子网络可根据国家法律法规变化及维护交易秩序、保护消费者权益需要，不时修改本协议。</p>
`,
      }).then(() => {
        // on close
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.myOverlay {
  z-index: 110;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 70%;
  .box {
    width: 100%;
    background: #fff;
    padding: 15px 0;
    border-radius: 6px;
    .box-title {
      color: $textColor;
      font-size: 24px;
    }
  }
}
</style>
