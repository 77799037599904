/**
 * 公共表单验证
 */
import validate from './validate'
import utils from './index'

// 校验手机号
var checkPhoneAndTel = (value) => {
  var telephone = validate.telephone
  if (telephone.test(value)) {
    return true
  }
  return false
}

// 邮箱
var checkEmail = (value) => {
  var email = validate.email

  if (email.test(value)) {
    return true
  }
  return false

}
//验正数字和英文和特殊字符
var checkOnpaste = (value) => {
  var paste = validate.validateOnpaste
  if (paste.test(value)) {
    return true
  }
  return false
}
//验证是否存在汉字
var checkChinese = (value) => {
  var reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
  if (reg.test(value)) {
    return true
  }
  return false
}
//只能输入中文""
var checkChinese2 = (value) => {
  var reg = validate.validateChinese
  if (reg.test(value)) {
    return true
  }
  return false
}
//校验省份证是否输入正确
var checkIdCard = (value) => {
  var idCard = validate.idCard
  if (idCard.test(value)) {
    return true
  }
  return false
}
//检验输入银行卡号是否正确
var checkBankCard = (value) => {
  let bankCard = validate.validateBankCard
  if (bankCard.test(value)) {
    return true
  }
  return false
}
//是否存在特殊字符
var chackRegExp = (value) => {
  var reg = new RegExp("[`~!@#$^&*%=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]")
  if (reg.test(value)) {
    return true
  }
  return false
}
//校验输入的身份证是否合法
var checkCard = (value) => {
  var city = {
    11: "北京",
    12: "天津",
    13: "河北",
    14: "山西",
    15: "内蒙古",
    21: "辽宁",
    22: "吉林",
    23: "黑龙江 ",
    31: "上海",
    32: "江苏",
    33: "浙江",
    34: "安徽",
    35: "福建",
    36: "江西",
    37: "山东",
    41: "河南",
    42: "湖北 ",
    43: "湖南",
    44: "广东",
    45: "广西",
    46: "海南",
    50: "重庆",
    51: "四川",
    52: "贵州",
    53: "云南",
    54: "西藏 ",
    61: "陕西",
    62: "甘肃",
    63: "青海",
    64: "宁夏",
    65: "新疆",
    71: "台湾",
    81: "香港",
    82: "澳门",
    91: "国外 "
  };
  let tip = ''
  let flag = true
  var idCard = validate.idCard
  // var sBirthday = value.substr(6, 4) + '-' + value.substr(10, 2) + '-' + value.substr(12, 2)

  // else if (!utils.getNewDate(sBirthday).year) {
  // 	tip = '身份证上的出生日期非法'
  // 	flag = false
  // } 

  if (!idCard.test(value)) {
    //身份证号格式错误
    tip = '你输入的身份证长度或格式错误'
    flag = false
  } else if (!city[value.substr(0, 2)]) {
    tip = '你的身份证地区非法'
    flag = false
  } else {
    if (value.length == 18) {
      value = value.split('');
      //加权因子
      var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
      //校验位
      var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
      var sum = 0;
      var ai = 0;
      var wi = 0;
      for (var i = 0; i < 17; i++) {
        ai = value[i];
        wi = factor[i];
        sum += ai * wi;
      }
      var last = parity[sum % 11];
      if (last != value[17]) {
        tip = "校验位错误";
        flag = false;
      }
    }
  }

  return flag
}

const validateRule = {
  checkPhoneAndTel,
  checkEmail,
  checkOnpaste,
  checkChinese,
  checkIdCard,
  checkBankCard,
  chackRegExp,
  checkCard,
  checkChinese2
}
export default validateRule